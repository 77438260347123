import { useState } from "react";
import styled from "styled-components";

import {
    MenuType
} from '../../types';

import { 
    setComma 
} from "../../utilities/convert";

var BASE_URL = process.env.REACT_APP_TARGET_URL;

const Container = styled.div`
    width: 100%;
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #9C27B0;
    border-radius: 16px;
    display: grid;
    grid-template-columns: 100%;
    gap: 20px;
`;

const RowWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;

const ColWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.2px;
    color: #333D4B;
`;

const MenuImage = styled.img`
    width: 22vw;
    height: 22vw;
    border-radius: 10px;
    margin-right: 15px;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 4px;
`;

const Label = styled.span<{mgr: number}>`
    margin-right: ${(props) => props.mgr}px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.175px;
    color: #000000;
`;

const Description = styled.span`
    margin-top: 16px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.175px;
    color: #7D93A6;
    /* height: 55px; */
    overflow: hidden;
    white-space: pre-line;
    word-wrap:break-all;
`;

interface MenuCardProps {
    menu: MenuType
}

export function MenuCard({
    menu
}: MenuCardProps){
    const [ hash, setHash ] = useState(Date.now());    
    
    return (
        <Container>
            <RowWrapper>
                <Title>{menu.name}</Title>
            </RowWrapper>
            <RowWrapper>
                <MenuImage 
                    onLoad={() => {

                    }}
                    src={BASE_URL + `/v1/menu/image/read/${menu.id}?${hash}`}
                />
                <ColWrapper>
                    <RowWrapper>
                        <Icon src={require('../../styles/assets/icon/price.png')} />
                        <Label 
                            mgr={12}
                        >{menu.price === 0 ? '상담 후 결정' : `${setComma(Number(menu.price))}원`}</Label>
                        <Icon src={require('../../styles/assets/icon/time.png')} />     
                        <Label
                            mgr={0}
                        >
                        {menu.duration}분 소요</Label>                   
                        
                    </RowWrapper>      
                    <RowWrapper>
                        <Description>
                            {menu.description}
                        </Description>                        
                    </RowWrapper>     
                </ColWrapper>         
            </RowWrapper>           
        </Container>
    );
}